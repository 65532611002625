import { Component } from '@angular/core';
import axios from "axios";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'Semesterticket';
  downloadInProgress = false;

  async downloadPass() {
    const text = await navigator.clipboard.readText();
    const validUrl = text.startsWith("https://www.ots-nrw.de/") || text.startsWith("https://ots-nrw.de/") || text.startsWith("http://www.ots-nrw.de/") || text.startsWith("http://ots-nrw.de/")
    if (!validUrl) {
      alert(`${text} is not a valid URL. Please copy the address that was opened when you directly accessed the semester ticket from the WWU service portal.`);
      return;
    }
    console.log(`URL = ${text}`);

    this.downloadInProgress = true;
    const response = await axios.post("https://semesterticket-backend-cyqwqfafza-lz.a.run.app/pass", { url: text }, { responseType: 'blob' });
    const blob = new Blob([response.data], { type: "application/vnd.apple.pkpass" });
    const url = window.URL.createObjectURL(blob);
    this.downloadInProgress = false;
    window.location.href = url;
  }
  å
  async startValidation() {
    if (confirm("In order to generate the pass, it is necessary to process personal data stored in the ticket. By continuing, you agree to these terms.")) {
      try {
        await this.downloadPass();
      } catch (e) {
        alert(e);
      }
      this.downloadInProgress = false;
    }
  }
}
